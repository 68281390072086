const supportedLngs = [
	'en-US',
	'es-ES',
	'fr-FR',
	'hi-IN',
	'id-ID',
	'it-IT',
	'ja-JP',
	'ko-KR',
	'ms-MY',
	'pt-PT',
	'ru-RU',
	'th-TH',
	'tr-TR',
	'vi-VN',
	'zh-CN'
];

export const nexti18nConfig = {
	react: { useSuspense: false },
	supportedLngs,
	ns: ['translation'],
	defaultLocale: 'id-ID', // Specify your default language here
	fallbackLng: 'id-ID' // Fallback language if the user's language is not supported
};
