import 'core-js/features/global-this';
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/en-150';
import '@formatjs/intl-numberformat/locale-data/id';
import '@formatjs/intl-numberformat/locale-data/en-GB';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-listformat/polyfill';
import '../styles/globals.scss';
import 'react-toastify/dist/ReactToastify.css';

import { useEffect } from 'react';
import dynamic from 'next/dynamic';
import { appWithI18Next } from 'ni18n';
import { nexti18nConfig } from '../next-i18next.config';
import { Amplify } from 'aws-amplify';
import AppContextProvider from '@/context/app.context/app.context.provider';
import BrandConfigStore from '@/context/store/brand-config';
// import useModalStore from '../src/features/modal/useModalStore';
import ConstantDataStore from '@/context/store/constant-data';
import { ThemeProvider } from '@/context/themeContext.context';
import usePersistentState from '@/hooks/usePersistentState';
import useRouterHook from '@/hooks/useRouterHook.hook';
import { useLanguageHook } from '@/hooks/useLanguageHook.hook';
import config from '@/constants/config';
import useAuth from '@/features/auth/useAuth';

const Layout = dynamic(() => import('@/components/templates/Layout/Layout'));

import BrandSetting from '../public/data/brandSettings.json';
import {
	useAppsyncCountLoginHook,
	useAppsyncSubscriptionWalletBalanceHook
} from '@/hooks/useAppsyncHook.hook';
import { useAppsyncSubscriptionDepowd } from '../hooks/useAppsyncHook.hook';

Amplify.configure(config.aws_amplify);

// const customScript

const MyApp = ({ Component, pageProps }) => {
	const { router } = useRouterHook();
	const { setPersistentState } = usePersistentState('', 'referralCode');
	// const theme = parseInt(BrandSetting?.layoutVersion ?? 1);

	const { brandConfig } = BrandConfigStore((state) => ({
		brandConfig: state.config
	}));
	const { loginToken: token } = useAuth();

	const { sessionToken, initializeAuthStore } = useAuth();

	const { getCurrencyList, getProviders, getBankList } = ConstantDataStore((state) => ({
		getCurrencyList: state.getCurrencyList,
		getProviders: state.getProviders,
		getBankList: state.getBankList
	}));

	// const { openModal } = useModalStore((state) => ({ openModal: state.openModal }));

	useEffect(() => {
		initializeAuthStore();
	}, []);

	useLanguageHook(); // languageπ
	useAppsyncSubscriptionWalletBalanceHook(); // appsync balance subscription
	useAppsyncCountLoginHook(); // appsync count player login
	useAppsyncSubscriptionDepowd(); // appsync subscription depowd

	const storePathValues = () => {
		const storage = globalThis?.sessionStorage;
		if (!storage) return;
		const prevPath = storage.getItem('currentPath');
		storage.setItem('prevPath', prevPath ? prevPath : '/');
		storage.setItem('currentPath', globalThis.location.pathname);
	};

	useEffect(() => {
		const isRegister = 'register' in router.query;
		const isLogin = token && token?.split(':')[0].toLowerCase() !== 'sessiontoken';
		if (isRegister && router.query.register && !isLogin) {
			const code = router.query.referralCode;
			// openModal('register');
			router.push('/register');

			if (code) {
				setPersistentState(code);
			}

			delete router.query;
			router.push(router);
		} else if (isRegister && router.query.register && isLogin) {
			delete router.query;
			router.push(router);
		}
	}, [router]);

	useEffect(() => storePathValues(), [router.asPath]);

	// Remove later
	useEffect(() => {
		const handlePostMessage = (event) => {
			if (event.data?.name === 'gameQuit') {
				window.location.href = brandConfig.websiteUrl;
			}
		};

		window.addEventListener('message', handlePostMessage);

		return () => {
			window.removeEventListener('message', handlePostMessage);
		};
	}, []);

	useEffect(() => {
		if (token || sessionToken) {
			getCurrencyList({ type: 'get', currencyType: 'all', brandId: BrandSetting.brandId });
			getProviders();
			getBankList();
		}
	}, [token, sessionToken]);

	// eslint-disable-next-line no-const-assign
	// pageProps = {
	// 	...pageProps,
	// 	brandConfig, //remove later
	// 	brandId: BrandSetting.brandId
	// 	// isLoadingConfig, // remove later
	// 	// theme
	// };

	return (
		<>
			<ThemeProvider>
				<AppContextProvider>
					<Layout>
						<Component {...pageProps} />
					</Layout>
				</AppContextProvider>
			</ThemeProvider>
		</>
	);
};

export default appWithI18Next(MyApp, nexti18nConfig);
