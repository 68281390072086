'use client';

import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useAuthStore = create(
	persist(
		(set) => ({
			sessionToken: null,
			loginToken: null,
			fingerprint: null,
			initializeStore: (loginToken, sessionToken, fingerprint) => {
				set((state) => ({
					...state,
					loginToken: loginToken,
					sessionToken: sessionToken,
					fingerprint: fingerprint
				}));
			},
			login: (loginToken) => {
				set((state) => ({ ...state, loginToken: loginToken }));
			},
			register: (loginToken) => {
				set((state) => ({ ...state, loginToken: loginToken }));
			},
			logout: () => {
				set((state) => ({ ...state, loginToken: null }));
			}
		}),
		{
			name: 'auth-storage'
		}
	)
);

export default useAuthStore;
