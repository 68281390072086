import { useEffect } from 'react';
import GlobalStateStore from '@/context/store/global-state';
import { UserApiService } from '@/services/api/user/user.api.service';
import useFetchApi from './useFetchApi.hook';
import useAuth from '../src/features/auth/useAuth';

const useAuthHook = () => {
	const { loginToken, isLoggedIn } = useAuth();
	const { setWalletBalance, setBalances } = GlobalStateStore((state) => ({
		setWalletBalance: state.setWalletBalance,
		setBalances: state.setBalances
	}));

	const {
		data: dataUserBalance,
		// loading: loadingUserBalance,
		// error: errorUserBalance,
		triggerFetch: triggerFetchUserBalance
	} = useFetchApi(UserApiService.UserBalance);

	useEffect(() => {
		if (dataUserBalance) {
			const walletData = dataUserBalance?.data ?? {};
			const walletBalance = walletData?.walletBalance ?? {};
			const walletActive = walletData?.activeWallet ?? null;
			// walletBalance, walletActive
			setWalletBalance({ walletBalanceList: walletBalance, activeWallet: walletActive });
			setBalances(walletData);
		}
	}, [dataUserBalance]);

	useEffect(() => {
		if (isLoggedIn) {
			getUser();
		}
	}, [isLoggedIn]);

	const getUser = () => {
		triggerFetchUserBalance({
			headers: {
				Authorization: loginToken
			},
			data: {
				type: 'all'
			}
		});
	};

	return { getUser };
};

export default useAuthHook;
