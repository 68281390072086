import { fetchData } from '../fetchData';

const GetBrandSetting = async (payload) => {
	try {
		const response = await fetchData(payload, 'getBrandSetting2');

		return response.data;
	} catch (error) {
		throw error.data;
	}
};

const GetArticleList = async (brandId) => {
	try {
		const response = await fetchData(brandId, 'getArticleList');

		return response.data;
	} catch (error) {
		throw error.data;
	}
};

const GetArticleDetail = async (brandId, articleId) => {
	try {
		const response = await fetchData({ brandId, articleId }, 'getArticleDetail');

		return response.data;
	} catch (error) {
		throw error.data;
	}
};

const BrandServices = { GetArticleList, GetArticleDetail, GetBrandSetting };

export default BrandServices;
