import axios from 'axios';

async function login(payload, sessionToken) {
	return axios
		.post(`${process.env.BASE_URL}/v1/login3`, payload, {
			headers: {
				Authorization: sessionToken
			}
		})
		.then((response) => response.data);
}

async function logout(loginToken) {
	return axios
		.post(`${process.env.BASE_URL}/v1/logout`, null, {
			headers: {
				Authorization: loginToken
			}
		})
		.then((response) => response.data);
}

async function register(payload, sessionToken) {
	return axios
		.post(`${process.env.BASE_URL}/v1/register3`, payload, {
			headers: {
				Authorization: sessionToken
			}
		})
		.then((response) => response.data);
}

async function getSessionToken(brandId, fingerprint) {
	return axios
		.post(`${process.env.BASE_URL}/v1/tsGenerateSessionToken`, {
			brandId: brandId,
			clientFingerprint: fingerprint
		})
		.then((response) => response?.data?.data?.sessionToken)
		.catch((error) => {
			throw error.response.data;
		});
}

async function verifySessionToken(brandId, fingerprint, sessionToken) {
	const newSessionToken = `${brandId.length}${brandId}${sessionToken}`;

	return axios
		.post(
			`${process.env.BASE_URL}/v1/tsSessionTokenAuthorizer`,
			{ clientFingerprint: fingerprint },
			{
				headers: {
					Authorization: newSessionToken
				}
			}
		)
		.then((response) => {
			return response.data.isTokenValid ?? false;
		})
		.catch((error) => {
			throw error.response.data;
		});
}

async function verifyLoginToken(loginToken) {
	return axios
		.post(`${process.env.BASE_URL}/v1/auth/checkSession`, null, {
			headers: {
				Authorization: loginToken
			}
		})
		.then((response) => {
			return response.data;
		})
		.catch(() => {
			return false;
		});
}

const authService = {
	login,
	logout,
	register,
	verifySessionToken,
	verifyLoginToken,
	getSessionToken
};

export default authService;
