/* eslint-disable no-undef */
const config = {
	aws_amplify: {
		// API: {
		// 	GraphQL: {
		// 		defaultAuthMode: 'lambda',
		// 		endpoint: process.env.NEXT_PUBLIC_AWS_APPSYNC_GRAPHQLENDPOINT,
		// 		apiKey: process.env.NEXT_PUBLIC_AWS_APPSYNC_APIKEY,
		// 		region: process.env.NEXT_PUBLIC_AWS_APPSYNC_REGION
		// 	}
		// }
		aws_appsync_graphqlEndpoint: process.env.NEXT_PUBLIC_AWS_APPSYNC_GRAPHQLENDPOINT,
		aws_appsync_region: process.env.NEXT_PUBLIC_AWS_APPSYNC_REGION,
		aws_appsync_authenticationType: process.env.NEXT_PUBLIC_AWS_APPSYNC_AUTHENTICATIONTYPE,
		aws_appsync_apiKey: process.env.NEXT_PUBLIC_AWS_APPSYNC_APIKEY
	}
};

export default config;
