import { useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify'; // V5
// import { generateClient } from 'awss-amplify/api'; // V6
import GlobalStateStore from '@/context/store/global-state';
import BalanceSubscription from '@/services/graphql/subscriptions/balance';
import CountLogin from '@/services/graphql/subscriptions/count-login';
import TransactionQueries from '../services/graphql/queries/transaction';
import BrandConfigStore from '@/context/store/brand-config';
import localStorageUtils from '@/utils/localStorage.utils';
import useUser from '@/features/user/userInfo/useUser';
// import { toast } from 'react-toastify';
// import apolloClient from '../services/ApolloClient';
// import {} from '@apollo/client';

// const client = generateClient();

// const useAppSyncSubscription = ({
// 	query,
// 	variables,
// 	authToken,
// 	callback,
// 	dependencies = [],
// 	shouldSubscribe = () => true // Default to always true if not provided
// }) => {
// 	useEffect(() => {
// 		let subscription;

// 		if (shouldSubscribe()) {
// 			subscription = client
// 				.graphql({
// 					query: query,
// 					variables: variables,
// 					authToken: authToken
// 				})
// 				.subscribe({
// 					next: ({ data }) => {
// 						// console.log('beneran ini kan : ', data);
// 						if (callback) callback(data);
// 					},
// 					error: (error) => console.warn(error)
// 				});
// 			// subscription = API.graphql(graphqlOperation(query, variables, authToken)).subscribe({
// 			// 	next: ({ value }) => {
// 			// 		console.log('value subs : ', value);
// 			// 		if (callback) callback(value);
// 			// 	}
// 			// });
// 		}

// 		// Cleanup on unmount
// 		return () => {
// 			if (subscription) subscription.unsubscribe();
// 		};
// 	}, dependencies);
// };

// const useAppSyncMutation = ({
// 	query,
// 	variables,
// 	authToken,
// 	callback,
// 	dependencies = [],
// 	shouldMutate = () => true // Default to always true if not provided
// }) => {
// 	useEffect(() => {
// 		console.log('shouldMutate : ', shouldMutate);
// 		console.log('authToken : ', authToken);
// 		if (shouldMutate()) {
// 			// let mutation;
// 			console.log('masuk gk sih : ', shouldMutate());
// 			client
// 				.graphql({
// 					query: query,
// 					variables: variables,
// 					authToken: authToken
// 				})
// 				.then((res) => {
// 					console.log('Mutation res :', res);
// 					if (callback) callback(res);
// 				});
// 			// API.graphql(graphqlOperation(mutation, variables, authToken))
// 			// 	.then((response) => {
// 			// 		console.log('Mutation response:', response);
// 			// 		if (callback) callback(response);
// 			// 	})
// 			// 	.catch((error) => {
// 			// 		console.error('Mutation error:', error);
// 			// 	});
// 		}

// 		// Cleanup on unmount
// 		return () => {
// 			// if(mutation)
// 			// You might want to add cleanup logic here if needed
// 		};
// 	}, dependencies);
// };

const useAppSyncSubscriptionOld = ({
	query,
	variables,
	authToken,
	callback,
	dependencies = [],
	shouldSubscribe = () => true // Default to always true if not provided
}) => {
	useEffect(() => {
		let subscription;

		if (shouldSubscribe()) {
			subscription = API.graphql(graphqlOperation(query, variables, authToken)).subscribe({
				next: ({ value }) => {
					if (callback) callback(value);
				}
			});
		}

		// Cleanup on unmount
		return () => {
			if (subscription) subscription.unsubscribe();
		};
	}, dependencies);
};

const useAppSyncMutationOld = ({
	query,
	variables,
	authToken,
	callback,
	dependencies = [],
	shouldMutate = () => true // Default to always true if not provided
}) => {
	useEffect(() => {
		// console.log('shouldMutate : ', shouldMutate);
		// console.log('authToken : ', authToken);
		if (shouldMutate()) {
			// let mutation;
			API.graphql(graphqlOperation(query, variables, authToken))
				.then((response) => {
					// console.log('Mutation response:', response);
					if (callback) callback(response);
				})
				.catch((error) => {
					console.error('Mut error:', error);
				});
		}

		// Cleanup on unmount
		return () => {
			// if(mutation)
			// You might want to add cleanup logic here if needed
		};
	}, dependencies);
};

//

// export const useAppsyncSubscriptionWalletBalanceHook = () => {
// 	const { user, token, isAuthenticated, setUpdateWalletBalance } = GlobalStateStore((state) => ({
// 		user: state.user,
// 		token: state.token,
// 		isAuthenticated: state.isAuthenticated,
// 		setUpdateWalletBalance: state.setUpdateWalletBalance
// 	}));

// 	useAppSyncSubscription({
// 		query: BalanceSubscription.SubscribeBalance,
// 		variables: { brandUsername: String(user?.brandUsername) },
// 		authToken: token,
// 		callback: (value) => {
// 			const valueParse = value?.newBalance;

// 			const newWalletBalance = JSON.parse(valueParse?.walletBalance);
// 			setUpdateWalletBalance({
// 				newWalletBalance: newWalletBalance
// 			});
// 		},
// 		dependencies: [isAuthenticated, token, user],
// 		shouldSubscribe: () => isAuthenticated && token && user // Your custom condition
// 	});

// 	return {};
// };

// export const useAppsyncCountLoginHook = () => {
// 	const { user, token, isAuthenticated } = GlobalStateStore((state) => ({
// 		user: state.user,
// 		token: state.token,
// 		isAuthenticated: state.isAuthenticated
// 	}));

// 	useAppSyncMutation({
// 		query: CountLogin.mutationLogin,
// 		authToken: token,
// 		dependencies: [isAuthenticated, token, user],
// 		shouldMutate: () => isAuthenticated && token && user
// 	});

// 	return {};
// };

export const useAppsyncSubscriptionWalletBalanceHook = () => {
	const token = localStorageUtils.getLoginToken();
	const { user } = useUser();
	const { setUpdateWalletBalance } = GlobalStateStore((state) => ({
		setUpdateWalletBalance: state.setUpdateWalletBalance
	}));

	useAppSyncSubscriptionOld({
		query: BalanceSubscription.SubscribeBalance,
		variables: { brandUsername: String(user?.brandUsername) },
		authToken: token,
		callback: (value) => {
			const valueParse = value?.data?.newBalance;

			const newWalletBalance = JSON.parse(valueParse?.walletBalance);
			setUpdateWalletBalance({
				newWalletBalance: newWalletBalance
			});
		},
		dependencies: [token, user],
		shouldSubscribe: () => token && user // Your custom condition
	});

	return {};
};

export const useAppsyncCountLoginHook = () => {
	const token = localStorageUtils.getLoginToken();
	const { user } = GlobalStateStore((state) => ({
		user: state.user
	}));

	useAppSyncMutationOld({
		query: CountLogin.mutationLogin,
		authToken: token,
		dependencies: [token, user],
		shouldMutate: () => token && user
	});

	return {};
};

export const useAppsyncGetDepoWdTransaction = ({ setDepoWdData }) => {
	const { brandId } = BrandConfigStore((state) => state.config);

	useAppSyncMutationOld({
		query: TransactionQueries.GetDepoWd,
		authToken: process.env.NEXT_PUBLIC_AWS_APPSYNC_APIKEY,
		variables: { brandId: brandId },
		callback: (value) => {
			setDepoWdData([...(value?.data?.getDepoWd?.report ?? [])]);
		},
		dependencies: [brandId],
		shouldMutate: () => brandId
	});

	return {};
};

export const useAppsyncSubscriptionDepowd = () => {
	const token = localStorageUtils.getLoginToken();
	const { user } = useUser();
	const { toast, toastStatus } = GlobalStateStore((state) => ({
		toast: state.toast,
		toastStatus: state.toastStatus
	}));

	useAppSyncSubscriptionOld({
		query: BalanceSubscription.SubscribeDepo,
		authToken: token,
		variables: { brandUsername: token?.split(':')[0] },
		callback: (value) => {
			const response = value.data.playerNotifDepoWd;
			toast(
				toastStatus.INFO,
				`Your ${response?.transactionType.toLowerCase()} has been ${
					response?.transactionStatus.toLowerCase() === 'success' ? 'approved' : 'declined'
				}`
			);
		},
		dependencies: [token, user],
		shouldMutate: () => token && user
	});

	return {};
};

// const notifSub = API.graphql(
// 	graphqlOperation(
// 		BalanceSubscription.SubscribeDepo,
// 		{ brandUsername: token?.split(':')[0] },
// 		token
// 	)
// ).subscribe({
// 	next: ({ value }) => {
// 		const response = value.data.playerNotifDepoWd;
// 		toast(
// 			toastStatus.INFO,
// 			`Your ${response.transactionType.toLowerCase()} has been ${
// 				response.transactionStatus.toLowerCase() === 'success' ? 'approved' : 'declined'
// 			}`
// 		);
// 	}
// });
