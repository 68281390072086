import { toast } from 'react-toastify';
import { createWithEqualityFn } from 'zustand/traditional';
import getToken from '@/services/getToken';
import UserServices from '@/services/api/user/user.services';
import CreateObj from '../../src/helpers/create-map-object.helper';
import localStorageUtils from '@/utils/localStorage.utils';
import walletUtils from '@/utils/wallet.utils';

// need to seperate the other state, user info and auth
const GlobalStateStore = createWithEqualityFn((set, get) => ({
	user: null,
	loading: true,
	referralCode: '',
	headerHeight: 0,
	url: '',
	isRendered: false,
	toastStatus: { SUCCESS: 'success', INFO: 'info', ERROR: 'error' },
	tsToken: null, // turnstile token
	tsTokenValid: false, // turnstile valid or not
	isTokenValid: false, // turnstile valid or not
	fingerprint: null,
	balances: {},
	currencySettings: {},
	walletBalance: new Map(),
	activeWalletBalance: null,
	setUser: (user) => set((state) => ({ ...state, user: user })),
	setLoading: (bool) => set((state) => ({ ...state, loading: bool })),
	setHeaderHeight: (value) => set((state) => ({ ...state, headerHeight: value })),
	toast: (status, message) => toast[status](message),
	setTsToken: (token) => set((state) => ({ ...state, tsToken: token })), // set turnstile token
	clearTsToken: () => set((state) => ({ ...state, tsToken: null })), // clear turnstile token
	setTsTokenValid: (bool) => set((state) => ({ ...state, tsTokenValid: bool })), // set turnstile valid
	setIsTokenValid: (bool) => set((state) => ({ ...state, isTokenValid: bool })), // set turnstile valid
	setFingerprint: (data) => set((state) => ({ ...state, fingerprint: data })),
	clearFingerprint: () => set((state) => ({ ...state, fingerprint: null })),
	setUrl: (value) => set((state) => ({ ...state, url: value })),
	menuClicked: (id) => {
		const elm = document.querySelector(id);
		window.scrollTo(0, elm.getBoundingClientRect().top + window.scrollY);
	},
	getBalances: async (payload) => {
		if (getToken()?.split(':')[0].toLowerCase() === 'sessiontoken') return;
		const response = await UserServices.GetCurrentBalance2(payload);

		const { walletBalance, walletSetting } = response;
		const mapObj = CreateObj.newMap(walletBalance);
		const refinedData = { ...response, walletBalance: mapObj };
		localStorage.setItem('balances', JSON.stringify(refinedData));
		set((state) => ({
			...state,
			balances: refinedData,
			currencySettings: walletSetting,
			loading: false
		}));

		return refinedData;
	},
	setWalletBalance: ({ walletBalanceList = {}, activeWallet = null }) => {
		const newWalletBalanceList = walletBalanceList ?? {};
		const walletBalanceMap = walletUtils.walletListToMap(newWalletBalanceList);
		const activeWalletBalanceMap = walletBalanceMap.get(activeWallet);
		localStorageUtils.setMap('userBalance', walletBalanceMap);

		set({
			walletBalance: walletBalanceMap,
			activeWalletBalance: activeWalletBalanceMap
		});
	},
	setUpdateWalletBalance: ({ newWalletBalance = {} }) => {
		const walletBalanceMap = walletUtils.updateWallet(get().walletBalance, newWalletBalance);
		const activeWalletBalanceMap = walletBalanceMap.get(get().activeWalletBalance?.currency);
		set({
			walletBalance: walletBalanceMap,
			activeWalletBalance: activeWalletBalanceMap
		});
	},
	setActiveWallet: (activeWallet) => {
		const activeWalletBalanceMap = get().walletBalance.get(activeWallet);
		set((state) => ({
			...state,
			activeWalletBalance: activeWalletBalanceMap
		}));
	},
	setLocale: (lang) => {
		localStorage.setItem('lang', lang);
	},
	setReferralCode: (code) => set((state) => ({ ...state, referralCode: code })),
	setBalances: (data) => {
		localStorageUtils.set('balance', data);
		set((state) => ({ ...state, balances: data, currencySettings: data?.walletSetting }));
	},
	setIsRendered: (bool) => set((state) => ({ ...state, isRendered: bool })),
	setLogout: () => {
		localStorageUtils.remove('userBalance');
		localStorageUtils.remove('balances');
		localStorageUtils.remove('balance');
		set((state) => ({
			...state,
			loading: false,
			user: null,
			balance: {},
			currencySettings: {}
		}));
	}
}));

export default GlobalStateStore;
