import { createWithEqualityFn } from 'zustand/traditional';
import { GetBrandSetting } from '@/utils/helper';
import BrandSetting from '../../public/data/brandSettings.json';
import BrandServices from '@/services/api/brand';
import localStorageUtils from '@/utils/localStorage.utils';

const BrandConfigStore = createWithEqualityFn((set) => ({
	config: BrandSetting,
	languages: [{ langCode: 'id-ID', lang: 'Bahasa Indonesia' }],
	selectedLanguage: 'id-ID',
	isLoadingConfig: true,
	setBrandSetting: (value) => set((state) => (state.config = value)),
	setSelectedLanguage: (language) => {
		localStorageUtils.set('lang', language || 'id-ID');
		set({ selectedLanguage: language });
	},
	getBrandSetting: GetBrandSetting().then((response) => {
		const languageOptions = response.langOption.filter((l) => l.isDisplay);
		set((state) => ({
			...state,
			config: { ...state.config, ...response },
			languages: languageOptions,
			isLoadingConfig: false
		}));
	}),
	getBrandSetting2: () =>
		BrandServices.GetBrandSetting({ brandId: BrandSetting.brandId, type: 'dynamic' }).then(
			(response) => {
				set((state) => ({ ...state, config: { ...state.config, ...response } }));
			}
		)
}));

export default BrandConfigStore;
