export const updateTheme = (newTheme) => {
	Object.keys(newTheme).forEach((key) => {
		document.documentElement.style.setProperty(`--${key}`, newTheme[key]);
	});
};

export const gradientList = {
	gold: {
		gradientColor:
			'linear-gradient(to top, #541513 0%, #8d5027 8%, #cb9552 38%, #fff2db 56%, #d7b189 80%, #ab6c2e 92%, #9c530d 96%)',
		primaryColor: '#000000',
		primaryGradientStatic: '#cb9552',
		fillColor: '#383838'
	},
	carbon: {
		gradientColor:
			'linear-gradient(to bottom, #040404 0%, #0a0a0a 13%, #131313 25%, #1f1f1f 38%, #414141 50%, #1f1f1f 64%, #131313 75%, #0a0a0a 88%, #040404 100%)',
		primaryColor: '#000000',
		primaryGradientStatic: '#cb9552',
		fillColor: '#C1C1C1'
	},
	platinum: {
		gradientColor:
			'linear-gradient(to bottom, #bfbdbe 0%, #cccbcc 4%, #e4e3e4 12%, #f3f2f3 20%, #f8f7f8 26%, #f2f1f2 31%, #e1e0e1 37%, #c6c4c5 43%, #bfbdbe 45%, #bfbdbe 58%, #cecccd 62%, #e5e3e4 70%, #f2f0f1 77%, #f7f5f6 83%, #c0bebf 100%)',
		primaryColor: '#000000',
		primaryGradientStatic: '#cb9552',
		fillColor: '#000000'
	},
	darkdesaturatedblue: {
		gradientColor: 'linear-gradient(to bottom, #3067ff 0%, #b0cdff 50%, #0574fa 100%)',
		primaryColor: '#000000',
		primaryGradientStatic: '#cb9552',
		fillColor: '#2b2a28'
	},
	bluelagoon: {
		gradientColor:
			'linear-gradient(to bottom, #005B7F 0%, #0080A7 12.5%, #00A3D9 25%, #5DDFFF 37.5%, #B9E3FF 50%, #5DDFFF 62.5%, #00A3D9 75%, #0080A7 87.5%, #005B7F 100%)',
		primaryColor: '#000000',
		primaryGradientStatic: '#cb9552',
		fillColor: '#2b2a28'
	},
	silver: {
		gradientColor:
			'linear-gradient(to bottom, #3a3a3a 0%, #454545 25%, #d5d5d5 50%, #535353 75%, #3c3c3c 100%)',
		primaryColor: '#000000',
		primaryGradientStatic: '#cb9552',
		fillColor: '#2b2a28'
	}
	// ... more gradients
};
