import { useRouter } from 'next/router';

const useRouterHook = () => {
	const router = useRouter();

	const routerGoToPreviousPage = () => {
		router.back();
	};

	const routerGoToPage = ({ url }) => {
		if (url && url !== '' && router.asPath !== url) {
			router.push(url);
		}
	};

	const routerGoToReloadPage = ({ url }) => {
		if (url && url !== '' && router.asPath !== url) {
			router.push(url).then(() => window.location.reload());
		}
	};

	const GoToReloadPage = ({ url }) => {
		if (url && url !== '' && router.asPath !== url) {
			window.location.href = url;
		}
	};

	return { router, routerGoToPreviousPage, routerGoToPage, routerGoToReloadPage, GoToReloadPage };
};

export default useRouterHook;
