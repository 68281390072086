import { createWithEqualityFn } from 'zustand/traditional';
import Constant from '@/services/api/constant/constant';
import TransactionServices from '@/services/api/transaction/transaction';
import BrandSetting from '../../public/data/brandSettings.json';

const ConstantDataStore = createWithEqualityFn((set) => ({
	countries: [],
	currencies: [],
	providers: [],
	paymentChannel: [],
	getCountries: Constant.GetCountries()
		.then((response) => {
			set((state) => ({ ...state, countries: response.data }));
		})
		.catch((err) => console.error(err)),
	getCurrencyList: (payload) =>
		Constant.GetCurrencies(payload)
			.then((response) => {
				set((state) => ({ ...state, currencies: response.data }));
			})
			.catch(() => console.error('err')),
	getProviders: () =>
		TransactionServices.GameTransactionNoAuth({
			systemType: 'gameProvider',
			brandId: BrandSetting.brandId,
			gameCategory: 'all'
		})
			.then((response) => {
				set((state) => ({ ...state, providers: response.data }));
			})
			.catch((err) => console.error(err)),
	getBankList: () => {
		Constant.GetBankList({
			paymentType: 'ALL',
			type: 'getByPaymentType',
			brandId: BrandSetting.brandId
		})
			.then((response) => {
				set((state) => ({ ...state, paymentChannel: response.data }));
			})
			.catch(() => console.error('err'));
	}
}));

export default ConstantDataStore;
